import { ElementRef, Injectable } from '@angular/core';
declare var bootstrap: any;
@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private observer: MutationObserver | null = null;

  constructor() {}
  initTooltips(autoHide: any, elRef: any) {
    const tooltipTriggerList = elRef.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl: HTMLElement) => {
      const tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: 'manual',
      });

      tooltipTriggerEl.addEventListener('mouseenter', () => {
        tooltip.show();

        if (autoHide === true) {
          setTimeout(() => {
            tooltip.hide();
          }, 3000);
        }
        tooltipTriggerEl.addEventListener('mouseleave', () => {
          tooltip.hide(); // Ensure tooltip hides when the mouse leaves early
        });
      });
    });
  }

  disconnectObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }
}
